export const environment = {
  production: true,
  env: 'prod',
  use_hash_for_routing: false,
  tinyMCEApiKey: 'sar0ftdfre1o8w5eb6c0khfmgj2q3m3g9zerjkxbi2rcicsw',

  site_id: 'prepmir',
  site_name: 'prepMIR',
  defaultLanguage: 'es',
  locale: 'es-ES',
  api_base_url: 'https://api-v2.prepmir.es/api',
  stripe_api_key:
    'pk_live_51Kcr2lFvEMzZRdPmh4HnJnbiQuY65TdX6n6Q33RS5cJshiTmwHfdez4ohF92jQHtpiEVRPVxxsgqgYBvnkjicPMO00ZCIdolFO',
  google_web_id: '765510329267-tcuc8p01lpfn5u71fceg22288n2vdif2.apps.googleusercontent.com',
  pusher_id: '2614bafe0c6486fcb67c',
  fb_id: '215852823176338',
  google_play_id: 'es.prepmir.app',
  appstore_id: 'id1527248161',
  appstore_service_id: 'es.prepmir.service',
  apple_redirect_uri: 'https://www.prepmir.es',
  pushChannel: 'prepmir_push_channel',
  pushName: 'prepMIR',
  intercomAppId: 'taxbq1ei',
  intercomHelpdeskUrl: 'https://intercom.help/prepmir',
  facebook_url: 'https://www.facebook.com/prepmir',
  instagram_url: 'https://www.instagram.com/prepmir/',
  tiktok_url: 'https://www.tiktok.com/@prepmir',
  telegram_url: 'https://t.me/+05uxmdQ410M2YTdk',
  domain: 'www.prepmir.es',
  adwordsKey: 'AW-980716637/E5usCO7xo50ZEN2Y0tMD',
  v2ReleaseDate: '2025-02-06 12:00:00',
};
